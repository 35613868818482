import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ContenedoresCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_CONTENEDORES = process.env.REACT_APP_URL_CONTENEDORES;
  const URL_ARTICULOS_CONTENEDOR = process.env.REACT_APP_URL_ARTICULOS_CONTENEDOR;
  const [t, i18n] = useTranslation("global");

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [selectedNaviera, setSelectedNaviera] = useState("");
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedAgente, setSelectedAgente] = useState("");
  const [selectedForwarder, setSelectedForwarder] = useState("");
  const [proveedores, setProveedores] = useState([]);

  const [fecha, setFecha] = useState(hoy);
  const [numeroInterno, setNumeroInterno] = useState("0");
  const [contenedor, setContenedor] = useState("");
  const [fechaETA, setFechaETA] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [totalGeneral, setTotalGeneral] = useState(0);
  const [totalCantidad, setTotalCantidad] = useState(0);
  const [puerto, setPuerto] = useState("");
  const [direccionEntrega, setDireccionEntrega] = useState("NA");
  const [modalidad, setModalidad] = useState("");
  const [asegurado, setAsegurado] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 1,
      descripcion: "NA",
      precio: 1,
      total: 1,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  

      axios
      .get(`${URL_CLIENTES}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allClientes = res.data;
          setClientes(allClientes);
        })
        .catch((err) => {
          console.log(err);
        });

    
      
      axios
      .get(`${URL_PROVEEDORES}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allProveedores = res.data;
          setProveedores(allProveedores);
        })
        .catch((err) => {
          console.log(err);
        });
       
  }, []);

  const saveOrdenCompra = async (event) => {
    event.preventDefault();
   

    let totalArticulos = inputFields.length;

    if(inputFields[0].articulos == "" || selectedCliente =="" || selectedAgente == "" || selectedForwarder == ""){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Debes completar los datos de Articulos, Cliente, Agente y Forwarder`,
      });
      return
    }

    setValidaBoton(false);
    toggleProgreso();

    try {
      await axios
        .post(
          URL_CONTENEDORES,
          {
            fecha,
            navieras: selectedNaviera,
            clientes: selectedCliente,
            agentes: selectedAgente,
            forwarders: selectedForwarder,
            articulos: inputFields[0].articulos,
            numeroInterno,
            contenedor,
            etaEstimada: fechaETA,
            etaReal: fechaETA,
            totalGastos:0,
            totalGeneral,
            totalCantidad,
            observaciones,
            totalCargos: 0,
            puerto,
            modalidad,
            direccionEntrega,
            terminales:"659751b809da69446cc05f3d",
            programas:"65984b7d111f341061f6c353",
            patentes:"66db35dea6b7de33346cd392",
            asegurado,
            rastreo:"No"
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            axios
              .post(
                URL_ARTICULOS_CONTENEDOR,
                {
                  fecha,
                  contenedores: data.data._id,
                  navieras: selectedNaviera,
                  clientes: selectedCliente,
                  agentes: selectedAgente,
                  forwarders: selectedForwarder,
                  articulos: a.articulos,
                  cantidad: parseFloat(a.cantidad),
                  descripcion: a.descripcion,
                  precio: parseFloat(a.precio),
                  total: parseFloat(a.total),
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Contenedor",
                        detalle: `${selectedNaviera} - ${selectedCliente} - ${totalGeneral}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                      setModalProgreso(false)
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
                setModalProgreso(false)
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data.message}`,
          });
          console.log(error);
          setValidaBoton(true);
          setModalProgreso(false)
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
      setValidaBoton(true);
      setModalProgreso(false)
    }
  };


  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 1,
        descripcion: "NA",
        precio: 1,
        total: 1,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

 

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.contenedoresCreate ? (
        <div className="card container col-12">
          <h3 align="center">{t("Nuevo")} {t("Contenedor")}</h3>
          <Form onSubmit={saveOrdenCompra}>
            <Row>
              <Col md={3}>
                <Label>{t("Fecha")}</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  disabled
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">{t("Cliente")}</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedCliente}
                  required
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                  }}
                >
                  <option value="0">{t("Selecciona")}</option>
                  {clientes
                    .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                    .map((a) => {
                        return <option value={a._id}>{a.nombre_comercial}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">{t("Naviera")}</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedNaviera}
                  required
                  onChange={(e) => {
                    setSelectedNaviera(e.target.value);
                  }}
                >
                  <option value="0">{t("Selecciona")}</option>
                  {proveedores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if(a.tipo === "Naviera"){
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </Col>
             
              <Col md={3}>
                <Label className="mr-sm-2">{t("Agente Aduanal")}</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedAgente}
                  required
                  onChange={(e) => {
                    setSelectedAgente(e.target.value);
                  }}
                >
                  <option value="0">{t("Selecciona")}</option>
                  {proveedores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if(a.tipo === "Agente"){
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </Col>
              
              </Row>
              <Row>
              <Col md={3}>
                <Label className="mr-sm-2">{t("Forwarder")}</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedForwarder}
                  required
                  onChange={(e) => {
                    setSelectedForwarder(e.target.value);
                  }}
                >
                  <option value="0">{t("Selecciona")}</option>
                  {proveedores
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if(a.tipo === "Forwarder"){
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </Col>
              {/* <Col md={3}>
                <Label>{t("Numero Interno")}</Label>
                <Input
                  type="text"
                  placeholder="NumeroInterno"
                  value={numeroInterno}
                  required
                  onChange={(e) => {
                    setNumeroInterno(e.target.value);
                  }}
                />
              </Col> */}
              <Col md={3}>
                <Label>{t("Contenedor")}</Label>
                <Input
                  type="text"
                  placeholder="Contenedor"
                  value={contenedor}
                  required
                  onChange={(e) => {
                    setContenedor(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>{t("Puerto")}</Label>
                <Input
                  type="select"
                  placeholder="Puerto"
                  value={puerto}
                  required
                  onChange={(e) => {
                    setPuerto(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Aeropuero CDMX">Aeropuero CDMX</option>
                  <option value="Laredo">Laredo</option>
                  <option value="Lazaro Cardenas">Lazaro Cardenas</option>
                  <option value="Manzanillo">Manzanillo</option>
                  <option value="Progreso">Progreso</option>
                  <option value="Tijuana">Tijuana</option>
                  <option value="Veracruz">Veracruz</option>
                </Input>
              </Col>
              <Col md={3}>
              <Label>{t("Asegurado")}</Label>
              <Input
                type="select"
                placeholder="Asegurado"
                value={asegurado}
                required
                onChange={(e) => {
                  setAsegurado(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                <option value="No">No</option>
                <option value="Si">Si</option>
                <option value="No Aplica">No Aplica</option>
              </Input>
            </Col>
              </Row>
              <Row>
              <Col md={3}>
              <Label>{t("Modalidad")}</Label>
              <Input
                type="select"
                placeholder="Modalidad"
                value={modalidad}
                required
                onChange={(e) => {
                  setModalidad(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Tren">Tren</option>
                <option value="Camion">Camion</option>
              </Input>
            </Col>

              <Col md={3}>
                <Label>{t("Fecha")} ETA</Label>
                <Input
                  type="date"
                  placeholder="Fecha ETA"
                  value={fechaETA}
                  required
                  onChange={(e) => {
                    setFechaETA(e.target.value);
                  }}
                />
              </Col>
              
            <Col md={6}>
                <Label>{t("Direccion de Entrega")}</Label>
                <Input
                  type="text"
                  placeholder="Direccion Entrega"
                  value={direccionEntrega}
                  required
                  onChange={(e) => {
                    setDireccionEntrega(e.target.value);
                  }}
                />
              </Col>
            </Row>
            
            <Row>
            <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  {t("Observaciones")}
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
             
              <Col md={10}>
                <h4 id="logoutBoton">
                  TOTAL {totalCantidad} pzas. /{" "}
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalGeneral)}
                </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">{t("Articulos")}</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">{t("Descripcion")}</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">{t("Cantidad")}</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">{t("Precio")} USD</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      // bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        handleChangeInput(inputField.id, event);
                      }}
                    >
                      <option value="0">{t("Selecciona")}</option>
                      {articulos
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.name}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>

                  <Col md={2}>
                    <Input
                      // bsSize="sm"
                      name="descripcion"
                      type="text"
                      placeholder="descripcion"
                      value={inputField.descripcion}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={2}>
                    <Input
                      // bsSize="sm"
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    {/* <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button> */}
                  </Col>
                </Row>
              </div>
            ))}
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                {t("Guardar")}
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                {t("Guardar")}
              </Button>
            )}
            <Button
              href="/ListadoContenedores"
              className="btn btn-danger"
              id="botonListado"
            >
               {t("Regresar")}
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>{t("Progreso")}</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                {t("Este proceso puede tardar varios segundos.")}
                <br />
                {t("Por favor no cierre ni refresque su navegador.")}
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ContenedoresCreate;
