import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function DevolucionesInversaCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_DEVOLUCIONES_INVERSA = process.env.REACT_APP_URL_DEVOLUCIONES_INVERSA;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [fecha, setFecha] = useState(hoy);
  const [concepto, setConcepto] = useState("");
  const [observaciones, setObservaciones] = useState("NA");

  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);
  const [moneda, setMoneda] = useState("");
  const [folio, setFolio] = useState("");
  

  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [importe, setImporte] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useMemo(() => {
    axios
    .get(`${URL_CLIENTES}`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });



  }, [selectedCliente]);

  const savePago = (event) => {
    event.preventDefault();
    setValidaBoton(false);
        axios.post(
          URL_DEVOLUCIONES_INVERSA,
          {
            fecha,
            importe,
            clientes: selectedCliente,
            concepto,
            bancos: selectedBanco,
            observaciones,
            folio
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then((data) => {
        // PDFOC()
        axios
        .post(
        `${URL_MOVIMIENTOS_BANCOS}`,
        {
            fecha,
            importe: -importe,
            tipo:"Egreso",
            bancos: selectedBanco,
            devolucionesInversa:data.data._id,
            moneda,
            tipoCambio:1
        },
        {
            headers: {
            Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
            )}`,
            },
        }
        ).then(async() => {


          axios.post(
            URL_LOGS,
            {
              tipo: "Crear Devolucion Inversa",
              detalle: `Fecha: ${fecha} / Cliente: ${selectedCliente} / Importe: ${importe} `,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
          Swal.fire("Good job!", "Creado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true);
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      });
  };

  
  const options = clientes.map((option) => {
    const junta = option.nombre_comercial;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaBanco(e) {
    setSelectedBanco(e);
    bancos.map((a) => {
      if (a._id == e) {
        return setMoneda(a.moneda);
      }
    });
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.devolucionesCreate ? (
        <div className="card container col-sm-8">
          <h3 align="center">Captura una Devolucion</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={4}>
                  <Label className="mr-sm-2">Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col md={4}>
                  <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      if (selectedCliente) {
                        setValue(selectedCliente);
                        setSelectedCliente(selectedCliente._id);
                      }
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>

                <Col md={3}>
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    type="select"
                    value={selectedBanco}
                    onChange={(e) => {
                      jalaBanco(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Banco</option>
                    {bancos
                      .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                      .map((a) => {
                        if(a.moneda == "MXN"){
                        return (
                          <option value={a._id}>
                            {a.banco} {a.cuenta}
                          </option>
                        );}
                      })}
                  </Input>
                </Col>
                </Row>
                <Row>
                <Col md={4}>
                  <Label className="mr-sm-2">Concepto</Label>
                  <Input
                    type="select"
                    value={concepto}
                    onChange={(e) => {
                      setConcepto(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Concepto</option>
                    <option value="Bonificacion">Bonificacion</option>
                    <option value="Descuento">Descuento</option>
                    <option value="Regreso">Regreso</option>
                  </Input>
                </Col>
                <Col md={4}>
                  <Label className="mr-sm-2">Observaciones</Label>
                  <Input
                    type="text"
                    value={observaciones}
                    onChange={(e) => {
                      setObservaciones(e.target.value);
                    }}
                  />
                </Col>
                  <Col sm={3}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Importe
                    </Label>

                   <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={importe}
                      className={`form-control`}
                      onValueChange={(value, name) => {
                        setImporte(value)
                      }}
                    />

                  </Col>
               
              </Row>
              <Row>
                <Col md={4}>
                  <Label className="mr-sm-2">Folio</Label>
                  <Input
                    type="text"
                    value={folio}
                    required
                    onChange={(e) => {
                      setFolio(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </FormGroup>
            <br />
            <Row>
              {validaBoton ? (
                <Button className="btn btn-success" onClick={savePago}>
                Registrar
              </Button>
              ):(
                <Button className="btn btn-success" disabled>
                Registrar
              </Button>
              )}

              <Button
                href="/ListadoDevolucionesInversa"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      
    </>
  );
}

export default DevolucionesInversaCreate;
